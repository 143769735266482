<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation @submit.prevent="onSubmit">
      <div>
        <VAlert type="info" outlined>Выберите проверяющего, который возьмет в работу заявку. Либо не выбирайте ни одного, чтобы любой из них мог сделать это сам</VAlert>
        <VDataTable
            fixed-header
            item-key="id"
            show-select
            single-select
            v-model="reviewer"
            :headers="[
              { text: 'ID', value: 'id', sortable: false },
              { text: 'ФИО', value: 'name', sortable: false },
              { text: 'email', value: 'email', sortable: false},
            ]"
            :items="listItems"
            :mobile-breakpoint="0"
        ></VDataTable>
      </div>
      <div class="mt-3">
        <VBtn color="primary" depressed class="mr-3" :disabled="!valid || selectedReviewerId === initialReviewerId || loading" @click="onSubmit">Сохранить</VBtn>
        <VBtn v-if="selectedReviewerId !== initialReviewerId" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>

import {find, first, get} from 'lodash-es';
import {mapGetters} from 'vuex';

export default {
  components: {},
  props: {
    step: { type: Object, required: true },
  },
  data() {
    return {
      valid: true,
      reviewer: [this.getInitialReviewer()],
    }
  },
  computed: {
    ...mapGetters({
      loading: 'pkosmr/pending',
    }),
    listItems() {
      return get(this.step, 'possibleReviewers', []);
    },
    initialReviewerId() {
      return get(this.getInitialReviewer(), 'id', null);
    },
    selectedReviewerId() {
      return get(first(this.reviewer), 'id', null);
    }
  },
  methods: {
    getInitialReviewer() {
      return find(get(this.step, 'possibleReviewers', []), {id: get(this.step, 'reviewerId')});
    },
    onSubmit() {
      this.$emit('submit', {reviewerId: this.selectedReviewerId});
    },
    onReset() {
      this.$emit('reset');
      this.onInit();
    },
    onInit() {
      this.reviewer = [this.getInitialReviewer()];
    },
  },
  watch: {
    step: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.onInit();
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {}
</style>