<template>
  <div :class="$style.root">
    <template v-if="type === 'BOOL'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" md="6" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" :rules="getRules(field)" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'TEXT'">
      <VRow dense>
        <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" />
          </template>
          <template v-else>
            <template v-if="maks">
              <VTextField v-model="field.value" v-mask="maks" :rules="getRules(field)" outlined dense hideDetails="auto" />
            </template>
            <template v-else>
              <VTextarea v-model="field.value" :rules="getRules(field)" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </template>
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'LIST'">
      <VRow dense>
        <VCol v-if="multiple" cols="12" md="6">
          <SelectMultiple v-model="values['LIST']" :options="options" required :readonly="readonly" />
        </VCol>
        <template v-else>
          <VCol v-for="(field, index) in values['LIST']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="options" />
            </template>
            <template v-else>
              <VSelect v-model="field.value" :items="options" :rules="getRules(field)" outlined dense hideDetails="auto" />
            </template>
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'FILE'">
      <VRow dense>
        <VCol cols="12">
          <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :required="required || values['FILE'][0]['required']" dated  />
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'TEXT_WITH_FILE'">
      <VRow dense>
        <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" />
          </template>
          <template v-else>
            <template v-if="maks">
              <VTextField v-model="field.value" v-mask="maks" :rules="getRules(field)" outlined dense hideDetails="auto" />
            </template>
            <template v-else>
              <VTextarea v-model="field.value" :rules="getRules(field)" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </template>
        </VCol>
        <VCol cols="12" md="6">
          <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :required="required || values['FILE'][0]['required']" dated />
        </VCol>
      </VRow>
    </template>
    <template v-if="type === 'LIST_WITH_FILE'">
      <VRow dense>
        <VCol v-if="multiple" cols="12" md="6">
          <SelectMultiple v-model="values['LIST']" :options="options" required :readonly="readonly" />
        </VCol>
        <template v-else>
          <VCol v-for="(field, index) in values['LIST']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="options" />
            </template>
            <template v-else>
              <VSelect v-model="field.value" :items="options" :rules="getRules(field)" outlined dense hideDetails="auto" />
            </template>
          </VCol>
        </template>
        <template v-if="needFileForListControl(values, multiple)">
          <VCol cols="12" md="6">
            <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :required="required || values['FILE'][0]['required']" dated />
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'BOOL_WITH_TEXT'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" :rules="getRules(field)" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
        <template v-if="values['BOOL'][0]['value'] === '1'">
          <VCol v-for="(field, index) in values['TEXT']" cols="12" :key="field.type + index">
            <div class="pt-3">
              <template v-if="field.readonly || readonly">
                <StaticField :value="field.value" />
              </template>
              <template v-else>
                <template v-if="maks">
                  <VTextField v-model="field.value" v-mask="maks" :rules="getRules(field)" outlined dense hideDetails="auto" />
                </template>
                <template v-else>
                  <VTextarea v-model="field.value" :rules="getRules(field)" outlined dense auto-grow rows="1" hideDetails="auto" />
                </template>
              </template>
            </div>
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'BOOL_WITH_FILE'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" :rules="getRules(field)" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
        <template v-if="values['BOOL'][0]['value'] === '1'">
          <VCol cols="12">
            <div class="pt-3">
              <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :required="required || values['FILE'][0]['required']" dated />
            </div>
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'BOOL_WITH_LIST'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" :rules="getRules(field)" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
        <template v-if="values['BOOL'][0]['value'] === '1'">
          <VCol v-for="(field, index) in values['LIST']" cols="12" :key="field.type + index">
            <div class="pt-3">
              <template v-if="field.readonly || readonly">
                <StaticField :value="field.value" />
              </template>
              <template v-else>
                <VSelect v-model="field.value" :items="options" :rules="getRules(field)" outlined dense hideDetails="auto" />
              </template>
            </div>
          </VCol>
        </template>
      </VRow>
    </template>
    <template v-if="type === 'BOOL_WITH_LIST_WITH_TEXT'">
      <VRow dense>
        <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
          </template>
          <template v-else>
            <VRadioGroup v-model="field.value" :rules="getRules(field)" row dense class="mt-0" hideDetails="auto">
              <VRadio label="Да" :value="'1'" />
              <VRadio label="Нет" :value="'0'" />
            </VRadioGroup>
          </template>
        </VCol>
        <template v-if="values['BOOL'][0]['value'] === '1'">
          <VCol cols="12">
            <div class="pt-3">
              <VRow dense>
                <VCol v-for="(field, index) in values['LIST']" cols="12" :key="field.type + index">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <VRow dense>
                        <VCol cols="12" md="6">
                          <template v-if="field.readonly || readonly">
                            <StaticField :value="field.value" :items="options" />
                          </template>
                          <template v-else>
                            <VSelect v-model="field.value" :items="options" :rules="getRules(field)" outlined dense hideDetails="auto" />
                          </template>
                        </VCol>
                        <VCol v-for="field in [values['TEXT'][index]]" cols="12" md="6" :key="field.type + index">
                          <template v-if="field.readonly || readonly">
                            <StaticField :value="field.value" />
                          </template>
                          <template v-else>
                            <template v-if="maks">
                              <VTextField v-model="field.value" v-mask="maks" :rules="getRules(field)" outlined dense hideDetails="auto" />
                            </template>
                            <template v-else>
                              <VTextarea v-model="field.value" :rules="getRules(field)" outlined dense auto-grow rows="1" hideDetails="auto" />
                            </template>
                          </template>
                        </VCol>
                      </VRow>
                    </div>
                    <div v-if="values['LIST'].length > 1 && !readonly" class="flex-grow-0 pl-2">
                      <VBtn fab dark small color="primary" depressed outlined @click="deleteValue(['LIST', 'TEXT'], index)">
                        <VIcon small dark v-text="'mdi-close'" />
                      </VBtn>
                    </div>
                  </div>
                </VCol>
                <VCol v-if="multiple && !readonly" cols="12">
                  <VBtn depressed small color="primary" @click="addValue(['LIST', 'TEXT'])">Добавить</VBtn>
                </VCol>
              </VRow>
            </div>
          </VCol>
        </template>
      </VRow>
    </template>
  </div>
</template>

<script>
import {forEach, head, merge, concat, some} from 'lodash-es';
import StaticField from '@/components/general/StaticField/StaticField';
import SelectMultiple from '@/components/general/SelectMultiple/SelectMultiple';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'CounterpartyDetailValues',
  components: {
    StaticField,
    SelectMultiple,
    FileFieldMultiple,
  },
  props: {
    type: { type: String },
    values: { type: Object },
    options: { type: Array },
    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    code: { type: String },
    label: { type: String },
    hint: { type: String },
  },
  computed: {
    maks() {
      if (/телефон/iu.test(this.label)) return '+ 7 (###) ### ## ##';
      if (/шт\.|число/iu.test(this.label + this.hint)) return '#################################';
      return null;
    },
    rules() {
      return [
        (v) => {
          if (this.required && !/.+/.test(v)) return 'Обязательное поле';
          if (/телефон/iu.test(this.label) && !/\d{11}/.test(v.replace(/\D/ig, ''))) return 'Укажите корректный номер телефона';
          if (/email|электронный адрес/iu.test(this.label) && !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v)) return 'Укажите корректный электронный адрес';
          if (/шт\.|число/iu.test(this.label + this.hint) && /\D/ig.test(v)) return 'Может содержать только целое число';
          return true;
        }
      ]
    }
  },
  methods: {
    getRules({ required }) {
      if (this.readonly) return [];
      return concat([
        (v) => {
          if (required && !/.+/.test(v)) return 'Обязательное поле';
          return true;
        }
      ], this.rules)
    },
    addValue(keys){
      forEach(keys, (key) => {
        this.values[key].push(merge(JSON.parse(JSON.stringify(head(this.values[key]))), {
          id: '',
          value: '',
        }));
      });
    },
    deleteValue(keys, index){
      forEach(keys, (key) => {
        this.values[key].splice(index, 1);
      });
    },
    needFileForListControl(values, multiple) {
      return multiple
        ? some(values['LIST'], (item) => !!item['value'].length && !/_net$/.test(item['value']))
        : !/_net$/.test(values['LIST'][0]['value']);
    },
  },
}
</script>

<style module lang="scss">
.root {}
</style>
