<template>
  <div :class="$style.root">
    <div :class="$style.badges">
      <div class="float-left"><b>{{author}}</b> {{date}}</div>
      <VChip v-if="draft" class="mr-1" outlined small>Черновик</VChip>
      <VChip v-if="actual" class="mr-1" outlined small>Актуальный</VChip>
    </div>
    <div :class="$style.body">
      <div v-if="edit">
        <VTextarea v-model="field"  rows="3" outlined auto-grow hide-details />
      </div>
      <div v-else>{{ message }}</div>
      <div :class="$style.controls">
        <VBtn v-if="actual && isContragent && !hasDraftComments && !answer" class="mr-1" outlined small color="primary" @click="onAnswer">Ответить</VBtn>
        <template v-if="draft && (!items || !items.length)">
          <template v-if="!edit">
            <VBtn class="mr-1" outlined small color="primary" @click="onEdit">Изменить</VBtn>
            <VBtn class="mr-1" outlined small color="error" @click="onDelete">Удалить</VBtn>
          </template>
          <template v-else>
            <VBtn class="mr-1" :disabled="!field" outlined small color="primary" @click="onSave">Сохранить</VBtn>
            <VBtn class="mr-1" outlined small color="secondary" @click="onReset">Отмена</VBtn>
          </template>
        </template>
      </div>
      <div v-if="answer && !hasDraftComments" class="pt-3">
        <CommentForm @submit="onSubmit" @reset="answer = false" />
      </div>
    </div>
    <div v-if="items && items.length" :class="$style.answer">
      <Comment v-for="comment in items" :class="$style.item" :counterparty="counterparty" v-bind="comment" :key="comment.id" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {find, get} from 'lodash-es';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import {ROLES} from '@/store/user/enums';

export default {
  name: 'Comment',
  components: {
    CommentForm,
  },
  props: {
    id: { type: String },
    counterparty: { type: String },
    question: { type: Object },
    date: { type: String },
    draft: { type: Boolean },
    actual: { type: Boolean },
    author: { type: String },
    message: { type: String },
    items: { type: Array },
  },
  data() {
    return {
      edit: false,
      answer: false,
      field: '',
    };
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
    hasDraftComments() {
      return !!find(this.items, { draft: true });
    },
  },
  methods: {
    ...mapActions({
      editComment: 'pkosmr/editComment',
      createComment: 'pkosmr/createComment',
      fetchCommentList: 'pkosmr/fetchCommentList',
      deleteComment: 'pkosmr/deleteComment',
      approveComment: 'pkosmr/approveComment',
    }),
    onSubmit(message) {
      const id = this.counterparty;
      const question = get(this.question, 'id');
      const parent = this.id;
      this.createComment({ id, parent, question, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий успешно отправлен',
        });
        return this.fetchCommentList({ id });
      });
    },
    onEdit() {
      this.edit = true;
      this.field = this.message;
    },
    onDelete() {
      const id = this.counterparty;
      const comments = this.id;
      this.deleteComment({ id, comments }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий удален',
        });
        return this.fetchCommentList({ id }).then((result) => {
          return result;
        });
      });
    },
    onAnswer() {
      this.answer = true;
    },
    onSave() {
      const id = this.id;
      const message = this.field;
      this.editComment({ id, message }).then(() => {
        this.edit = false;
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий обновлен',
        });
      })
    },
    onReset() {
      this.edit = false;
      this.field = this.message;
    },
    onApprove() {
      const id = this.id;
      const counterparty = this.counterparty;
      this.approveComment({ items: [ id ]}).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий принят',
        });
        return this.fetchCommentList({ id: counterparty }).then((result) => {
          return result;
        });
      })
    },
  }
}
</script>

<style module lang="scss">
.root {
  border: 1px solid #dee2e6;
  padding: 10px;
  & + & {
    margin-top: 20px;
  }
  & & {
    margin-top: 10px;
    background-color: #dcffd6;
  }
}
.body {}
.badges {
  overflow: hidden;
  margin-bottom: 10px;
  text-align: right;
}
.controls {
  margin-top: 10px;
}
.answer {
  margin-top: 10px;
}
</style>
