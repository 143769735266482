<template>
  <div :class="$style.root">
    <VForm v-model="valid" lazy-validation @submit.prevent="onSubmit">
      <VTextarea v-model="publicMessage" label="Комментарий для контрагента"  rows="3" outlined auto-grow hide-details />
      <VTextarea v-model="internalMessage" label="Комментарий для внутреннего использования" class="mt-3" rows="3" outlined auto-grow hide-details />
      <div v-if="needFiles" class="mt-3">
        <div>Приложите файлы к своему решению (документы, отчеты из Контур.Фокус и т.д.)</div>
        <FileFieldMultiple v-model="files" />
      </div>
      <div class="mt-3">
        <VBtn color="primary" depressed class="mr-3" :disabled="disabled || !valid" @click="onSubmit">Отправить</VBtn>
        <VBtn v-if="internalMessage || publicMessage" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
export default {
  name: 'DecisionForm',
  components: {
    FileFieldMultiple,
  },
  props: {
    disabled: { type: Boolean },
    decision: { type: String },
    needFiles: { type: Boolean, default: false },
  },
  data() {
    return {
      publicMessage: '',
      internalMessage: '',
      expertMark: '',
      files: [],
      valid: true,
      rules: {
        expertMark: [
          (value) => /\d{1,3}/.test(value) && value >= 0 && value <= 100 || !value || 'Укажите корректуню оценку от 0 до 100',
        ],
      }
    }
  },
  methods: {
    onSubmit() {
      const publicMessage = this.publicMessage;
      const internalMessage = this.internalMessage;
      const expertMark = this.expertMark;
      const files = this.files;
      this.$emit('submit', publicMessage, internalMessage, expertMark, files);
      this.publicMessage = '';
      this.internalMessage = '';
      this.expertMark = '';
      this.files = [];
    },
    onReset() {
     this.publicMessage = '';
     this.internalMessage = '';
     this.expertMark = '';
     this.files = [];
     this.$emit('reset');
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
