<template>
  <VCard tile :class="$style.root">
    <VToolbar flat dark color="primary">
      <VToolbarTitle class="px-2">Комментарии</VToolbarTitle>
      <VSpacer/>
      <VBtn icon dark @click="onClose">
        <VIcon>mdi-close</VIcon>
      </VBtn>
    </VToolbar>
    <VCardText class="pt-5">
      <div v-if="!items.length">Комментарии отсутствуют</div>
      <Comment v-for="comment in items" :class="$style.item" :counterparty="counterparty" v-bind="comment" :key="comment.id" />
      <div v-if="!hasDraftComments && isReviewer" class="pt-5">
        <CommentForm @submit="onSubmit" />
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import { debounce, get, groupBy, map, find, filter } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import CommentForm from '@/components/general/CommentForm/CommentForm';
import Comment from '@/views/pkosmr/CounterpartyCommentsView/components/Comment';
import {ROLES} from '@/store/user/enums';

export default {
  name: 'CounterpartyCommentsView',
  components: {
    Comment,
    CommentForm,
  },
  props: {
    counterparty: { type: String },
    question: { type: String },
  },
  computed: {
    ...mapGetters({
      loading: 'pkosmr/pending',
      list: 'pkosmr/comments',
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
    hasDraftComments() {
      return !!find(this.items, { draft: true });
    },
    items() {
      const question = this.question;
      const list = filter(get(this.list, 'items'), {
        question: {id: question}
      });
      const mapper = groupBy(list, 'parent');
      return map(get(mapper, '0'), (comment) => {
        return {
          ...comment,
          items: get(mapper, comment.id, []),
        }
      });
    },
  },
  methods: {
    ...mapActions({
      fetchCommentList: 'pkosmr/fetchCommentList',
      createComment: 'pkosmr/createComment',
      approveComment: 'pkosmr/approveComment',
    }),
    onUpdateState: debounce(function() {
      const id = this.counterparty;
      this.fetchCommentList({ id });
    }, 500),
    onClose() {
      const counterparty = this.counterparty;
      this.$router.push({ name: 'pkosmr/CounterpartyDetailView', params: { counterparty }});
    },
    onSubmit(message) {
      const id = this.counterparty;
      const question = this.question;
      this.createComment({ id, question, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий успешно отправлен',
        });
        return this.fetchCommentList({ id });
      });
    },
  },
  watch: {
    counterparty: function(counterparty) {
      if (counterparty) this.onUpdateState();
    },
    question: function(question) {
      if (question) this.onUpdateState();
    },
  },
  mounted() {
    this.onUpdateState();
  },
}
</script>

<style module lang="scss">
.root {}
.item {
  padding: 10px;

}
</style>
